html {
  /* --section-background-color: linear-gradient(
    to bottom left,
    /* rgba(17, 16, 16, 0.582),
    rgba(12, 8, 24, 0.904) */

  /* --image-gradient: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.678),
    rgba(12, 10, 22, 0.863)
  ); */

  --imp-text-color: #ffffff;
}

.nav-brand {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.nav-logo {
  width: 170px;
}

@font-face {
  font-family: "video-game";
  src: url(./Assets/GN-Koharuiro_Sunray.ttf);
}

.project-main-title{
  margin: 2rem;
  display: flex;
  align-items: center;
}

.project-main-title h1{
  font-size: 3rem;
}

.coolLink {
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;

  display: inline-block;
  position: relative;
}

.coolLink:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.coolLink:hover:after {
  width: 100%;
  left: 0;
}

/* --------- */
/* Navbar Section  */
/* --------- */

/*
@media screen and (max-width: 820px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #212529 !important;
    color: white !important
  }

  .navbar-nav .nav-item {
    display: none !important;
    //color: white !important 
  }

  .btn-14-n {
    display: none;
  }

  .projects {
    flex-direction: column;
  }

  .cards {
    flex-direction: column;
  }

  .project-card-main {
    width: 100%;
  }

  .project-main-title {
    margin: 1rem;
    justify-content: center;
  }

  .project-main-title h1 {
    font-size: 2rem;
    text-align: center;
  }

  .project-card-main .clickable {
    height: 300px;
  }

  .contact-us .red-footer footer{
    display: flex;
    flex-direction: column;
    text-align: center;
  }
 

  .red-footer footer .vl {
    margin: 0 !important;
    padding: 0 !important;
    height: auto;
  }

  .red-footer footer .p-4 {
    padding: 0.5rem !important;
  }
}

.navbar-logo {
  margin-top: 10px;
  font-size: 20px;
}

.offcanvas.offcanvas-end {
  width: 50%;
}
*/


@media screen and (max-width: 767px) {
  .coolLink {
    color: black !important;
  }

  .coolLink:after {
    background: black !important;
  }

  .offcanvas.offcanvas-end {
    width: 50%;
  }

}


  @media screen and (max-width: 750px) {
    .navbar-logo {

      /* color:white; */
      margin-top: 10px;
      /* font-size:20px; */
    }

    .margin {
      margin-top: 250px;
    }
  }

/* --------- */
/* Sponsors */
/* --------- */
.project-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
}

.project-card {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  height: auto !important;
}

.project-card-view {
  box-shadow: 0 4px 5px 3px #BB3B3B !important;
  color: white !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  /* height: 100% !important; */
}
.project-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 5px rgba(72, 72, 72, 0.57) !important;
}

.containerr {
  position: relative;
  text-align: center;
  color: white;
  font-family: "video-game";
  font-size: 1000px;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.stretch {
  object-fit: cover;
  height: 370px;
  width: 100%;
}
.bg-daark {
  --bs-bg-opacity: 1;
  background-color: #202020 !important;
}

.text-whitee {
  --bs-text-opacity: 1;
  color: white !important;
}

/* vertical lines in footer */
.vl {
  border-left: 1px solid white;
  height: 150px;
}

.sponsors-part {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8rem 8rem 4rem 8rem;
}

.sponsors-part h1 {
  font-size: 4rem;
  font-weight: bolder;
}

.sponsors-part p {
  font-size: 1.3rem;
  text-align: center;
  width: 75%;
}

.sponsors-part .buttons {
  display: flex;
  gap: 1rem;
}

.sliders {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sponsors-slide {
  display: flex;
  align-items: center;
  gap: 5rem;
}

@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -150%;
  }
}
.animate {
  width: 1800%;
  animation: marquee 15s linear infinite;
  top: 50%;
  transform: translate(0, -50%);
}

.animate.reverse {
  animation-direction: reverse;
}

.animate.delayed {
  transform: translate(2%, -50%);
}

.logo a {
  width: 80px;
  -webkit-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
@screen lg {
  @keyframes marquee {
    0% {
      left: 0;
    }
    100% {
      left: -150%;
    }
  }
  .animate {
    width: 750%;
    animation: marquee 10s linear infinite;
    top: 50%;
    transform: translate(0, -50%);
  }
  .logo a {
    width: 100px;
    -webkit-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    cursor: default;
  }
}

.slider-box {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.04) 0%,
    rgb(255, 255, 255) 100%
  );
}

/* skill */
#skill {
  width: 100%;
  /* background-color: #fbfbfb; */
  position: relative;
  margin-top: 0px;
}
.skill-main {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 111;
  padding-bottom: 51px;
  padding-top: 40px;
}

.skill-title {
  padding-top: 0px;
}

.skill-under {
  background-color: white;
}
.skill-details {
  padding-top: 0px;
}
.skill-details p {
  font-size: 17px;
  letter-spacing: 1px;
  color: #3c3c3c;
  padding: 0px;
  line-height: 27px;
  margin-top: 0px;
}
.content {
  width: 100%;
  margin: 0;
  position: relative;
  float: left;
  font-size: 12px;
  line-height: 2em;
}
.col {
  width: 100%;
}

#skill-main {
  list-style: none;
  padding: 26px 20px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

#skill-main li {
  margin-bottom: 29px;
  background: #f8f39e;
  height: 14px;
  border-radius: 3px;
  border-left: 1px solid #f8f39e;
  border-top: 1px solid #f8f39e;
  border-right: 1px solid #f8f39e;
  border-bottom: 1px solid #f8f39e;
}
#skill-main h3 {
  margin: 0px;
  position: absolute;
  margin-top: -20px;
  font-size: 13px;
  color: #333;
}
#skill-main li em {
  position: relative;
  top: -30px;
  float: left;
  color: white;
}

.projects {
  background: #202020;
  color: white;
  text-align: center;
  display: flex;
  max-width: 100%;
  overflow-x: scroll;
}


.cards {
  display: flex;
}

.red-footer{
  background: url("./images/mars-bg.jpg");
  color: white;
  padding: 1rem;
}
.black-footer {
  background-color: #000000;

  padding: 1rem;
}

/*This Part is for ALL OF THE mobile view*/

@media screen and (max-width: 540px){
  .main-hero-text-part {
    width: 100%;
  }

  .Description {
    font-size: 1rem;
    width: 100%;
  }

  .text-part-container {
    padding: 10rem 0 0 0;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .hero-title {
    text-align: center;
  }

  .statistics-container {
    padding-top: 1rem;
    margin: 0;
  }

  .statistics-number {
    font-size: 1.5rem;
  }

  .about-informations {
    padding-top: 3rem;
    justify-content: normal;
    height: auto;
  }

  .about-title {
    flex-direction: column;
  }

  .big-title {
    font-size: 5rem;
    text-align: center;
  }

  .sliding-img {
    width: 100%;
  }

  .about-desc {
    width: 100%;
  }

  .sponsors-part {
    padding: 1rem;
  }

  .sponsors-part h1 {
    font-size: 6rem;
    text-align: center;
  }

  .sponsors-part p {
    width: 100%;
    text-align: center;
  }
}

.Projects-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10rem;
  background: #f0f0f0;
}

.card-containers {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20vh;
  flex-wrap: wrap;
}

.item:hover {
  --expand: 1;
}
.item:before,
.item:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background: #cb4a4a;
  border: 2px solid #fff;
  top: 0;
  left: 0;
  z-index: -1;
}
.item:after {
  transform: translate(calc(var(--expand) * -2%), calc(var(--expand) * -2%));
  transition: transform 0.2s;
}
.item__content {
  transition: transform 0.2s;
  transform: translate(calc(var(--expand) * -4%), calc(var(--expand) * -4%));
}
a {
  color: #c7edef;
}

#sec-gallery {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.gallery-container {
  display: flex;
  width: 60%;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.image-card {
  width: 256px;
}

.center-block {
  width: 256px;
}



.proj{
  display: flex;
  justify-content: center;
  height: auto;
  gap: 5rem;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

.proj-reversed{
  display: flex;
  justify-content: center;
  height: auto;
  gap: 5rem;
  flex-direction: row;
  flex-wrap: wrap;
}

.proj-text{
  display: flex;
  flex-direction: column;
  width: 30%;
}

.proj-titles{
  line-height: .3rem;
}

.proj-images{
  border-radius: 1rem;
  max-width: 480px;
  max-height: 312px;
  box-shadow: 8px 8px 16px 2px rgba(0,0,0,0.3);
}

.img-project{
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 1rem;
}

.proj-description{
  border-radius: 1rem;
  width: 100%;
}

@media(max-width: 1120px) {
  .proj-text {
    width: 100%;
  }
  .proj {
    gap: 1rem;
  }
  .proj-titles {
    text-align: center;
  }

  .Projects-center {
    padding: 1rem;
  }
  .proj-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

#MarsChamber {
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background: url("images/MarsChamber.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  background-color: rgba(0, 0, 0, 0.69);

  box-shadow: 0px 2px 16px #999;
}