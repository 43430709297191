.project-card-main{
    height: 100%;
    width: 50%;
    background-position: center;
    background-size: cover;
    color: white;
    background-blend-mode: color;
    background-color: rgba(0, 0, 0, 0.39);
    text-align: right;
}

.clickable{
    width: 100%;
    height: 100vh;
    padding: 1rem;
    display: flex;
    flex-direction: column-reverse;
    text-decoration: none;
    color: #F5F5F5;
}

.clickable:hover{
    color: #dadada;
    background-color: rgba(255, 255, 255, 0.13);
}