#sec-farming-title{
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    background: url("../../../images/plantThingy.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    background-blend-mode: overlay;
    background-color: rgba(0, 0, 0, 0.69);

    box-shadow: 0px 2px 16px #999;
}

#sec-helios-title{
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    background: url("../../../images/helios.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    background-blend-mode: overlay;
    background-color: rgba(0, 0, 0, 0.69);

    box-shadow: 0px 2px 16px #999;
}

.main-title-farming{
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #ffffff;
}

.main-title-farming h1{
    font-size: 5rem;
    text-shadow: 5px 5px black;
    font-family: "video-game";
}

.main-title-farming h3{
    font-weight: normal;
    letter-spacing: 2px;
    font-family: "Open Sans";
    color: white;
}
/* Title section ends */

/* The Idea Section */
#sec-the-idea{
    width: 100%;
    height: 100%;
}

.ideaInformations-farming{
    display: flex;
    padding: 5rem 7.5rem;
    flex-direction: column;
    height: 100%;
    gap: 2rem;

    flex-direction: column;
    height: 100%;
    gap: 2rem;

    align-items: center;
    justify-content: center;

    background: url(https://theness.com/neurologicablog/wp-content/uploads/sites/3/2020/11/farming-Mars.jpg);
    background-size: 102%;

    background-blend-mode: overlay;
    background-color: rgba(0, 0, 0, 0.69);
    background-position: center;
    background-repeat: repeat-y;
}

.idea-title-farming{
    color: #f4c15d;
    font-family: "Times New Roman";
    font-size: 4rem;
}

.idea-image-farming{
    float: right;
    height: 0rem;
}

.idea-text-farming{
    display: grid;
    font-family: "Times New Roman";
    font-size: 1rem;
    color: #f7f7f7;
}

@media (max-width: 750px) {
    .ideaInformations-farming {
        padding: 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

}