/* CSS button */
.custom-btn {
  margin-top: 10px;
  width: auto;
  height: 60px;
  padding: 10px 25px;
  border: 1px solid #f5f5f5;
  font-family: "Lato", sans-serif;
  font-size: medium;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
}

.custom-btn.btn-14.startingred {
  border: 2px solid #bb3b3b;
}
.custom-btn.btn-14.red {
  border: 2px solid #bb3b3b;
}

/* 14 */
.btn-14 {
  background: transparent;
  color: #f5f5f5;
  z-index: 1;
  font-family: "Lato", sans-serif;
}

.btn-14:hover {
  color: black;
}

.btn-14.startingred {
  background: #bb3b3b;
  color: #f5f5f5;
}

.btn-14.startingred a {
  color: #f5f5f5;
}

.btn-14.startingred:after {
  background: #f5f5f5;
}

.btn-14.startingred:hover {
  color: #bb3b3b !important;
}

.btn-14.red {
  background: transparent;
  color: #bb3b3b;
}

.btn-14.red:hover {
  color: white;
}

.btn-14.red:after {
  background: #bb3b3b;
}

.btn-14.red a {
  color: white;
}

.btn-14.red:hover a {
  color: white !important;
}

.btn-14:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  background: #f5f5f5;
  transition: all 0.3s ease;
}
.btn-14:hover a {
  color: black !important;
}
.btn-14:hover:after {
  top: auto;
  bottom: 0;
  height: 100%;
}
.btn-14:active {
  top: 2px;
}
.btn-14 a {
  text-decoration: none;
  color: #f5f5f5;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}

/* 14 down */
.btn-15 {
  background: transparent;
  color: #f5f5f5;
  z-index: 1;
}

.btn-15.startingred {
  background: #bb3b3b;
  color: #f5f5f5;
}

.btn-15.startingred a {
  color: #f5f5f5;
}

.btn-15.startingred:after {
  background: #f5f5f5;
}

.btn-15.startingred:hover a {
  color: #bb3b3b !important;
}

.btn-15.red {
  background: transparent;
  color: #bb3b3b;
}

.btn-15.red:after {
  background: #bb3b3b;
}

.btn-15.red a {
  color: white;
}

.btn-15.red:hover a {
  color: white !important;
}

.btn-15:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  background: #f5f5f5;
  transition: all 0.3s ease;
}
.btn-15:hover {
  color: black !important;
}
.btn-15:hover:after {
  top: 0;
  bottom: auto;
  height: 100%;
}
.btn-15:active {
  top: 2px;
}
.btn-15 a {
  text-decoration: none;
  color: #f5f5f5;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

.custom-btn.btn-15.startingred {
  border: 2px solid #bb3b3b;
}
.custom-btn.btn-15.red {
  border: 2px solid #bb3b3b;
}
