#sec-airlock-title{
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    background: url("../../../images/hero-carousel/1.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-blend-mode: overlay;
    background-color: rgba(0, 0, 0, 0.69);

    box-shadow: 0px 2px 16px #999;
}
.main-title-airlock{
    font-family: "video-game";
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #ffffff;
}

.main-title-airlock h1{
    font-size: 3.5rem;
    text-wrap: normal;
    text-shadow: 5px 5px black;
}

.main-title-airlock h3{
    font-weight: normal;
    letter-spacing: 2px;
    font-family: "Open Sans";
    color: #FFFFE4;
}
/* Title section ends */

/* The Idea Section */
#sec-the-idea{
    width: 100%;
    height: 100%;
}

.ideaInformations-airlock{
    display: flex;
    padding: 5rem 7.5rem;
    flex-direction: column;
    height: 100%;
    gap: 2rem;

    align-items: center;
    justify-content: center;

    background: url(https://spacenews.com/wp-content/uploads/2016/01/Akihiko_Hoshide_near_the_airlock_in_the_Kibo_lab.jpg);
    background-blend-mode: overlay;
    background-color: rgba(0, 0, 0, 0.69);
    background-size: 102%;
    background-position: center;
    background-repeat: repeat-y;
}



/* The Idea ends */

.title-seperator{
    background: url("../../../images/mars-bg.jpg");
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    background-position: center;
    background-size: cover;
}

.title-seperator h1{
    color: #f4c15d;
    font-family: "Times New Roman";
    font-size: 3rem;
}


@media (max-width: 800px) {

    .ideaInformations-airlock {
        padding: 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

}