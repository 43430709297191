.about-informations {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

.about-title {
    display: flex;
    gap: 5rem;
    width: 90%;
    align-items: center;
    justify-content: center;
}

.big-title {
    font-size: 5rem;
    text-align: right;
}

.about-photo {
    width: 400px;
    height: 250px;
    object-fit: cover;
}

.about-desc {
    text-align: center;
    width: 57%;
    padding: 1rem;
}

.sliding-img {
    width: 511px;
    height: 300px;
    object-fit: cover;
}

@media screen and (max-width: 780px) {
    .about-title {
        gap: 2rem;
    }

    .big-title {
        font-size: 3.5rem;
    }
}

@media screen and (max-width: 680px) {
    .about-title {
        gap: 1.5rem;
    }

    .sliding-img {
        width: 400px;
    }

    .big-title {
        font-size: 2.5rem;
    }
}