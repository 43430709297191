.footer-social-links{
    font-size: 14px;
    padding: 1rem 0;
    display: flex;
    z-index: 2;
}
.footer-social-links a{
    width: 25px;
    height: 25px;
    line-height: 25px !important;
    position: relative;
    margin: 0 1rem;
    text-align: center;
    display: inline-block;
    color: #F7F7FF;

    -webkit-transition: all 0.17s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -moz-transition: all 0.17s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -o-transition: all 0.17s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -ms-transition: all 0.17s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    transition: all 0.17s cubic-bezier(0.300, 0.100, 0.580, 1.000);
}
.footer-social-links a i,
.footer-social-links a span{
    position: relative;
    top: 2px;
    left: 1px;
}
.footer-social-links a:before{
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 2px;
    left: 1px;
    border: 1px solid #F7F7FF;

    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;

    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);

    -webkit-transition: all 0.17s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -moz-transition: all 0.17s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -o-transition: all 0.17s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -ms-transition: all 0.17s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    transition: all 0.17s cubic-bezier(0.300, 0.100, 0.580, 1.000);

}
.footer-social-links a:hover{
    color: black;
}
.footer-social-links a:hover:before{
    background: #F7F7FF;
}