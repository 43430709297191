/* CSS button */
.custom-btn-n {
    width: auto;
    height: 40px;
    padding: 0 20px;
    border: 1px solid #F5F5F5;
    font-family: 'Lato', sans-serif;
    font-size: medium;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    border-radius: 1rem;
}

/* 14 */
.btn-14-n {
    background: transparent;
    color: #F5F5F5;
    z-index: 1;
}

.btn-14-n:hover {
    background: white;
    color: black;
    transition: 0.3s;
}

.btn-14-n:active {
    top: 2px;
}
.btn-14-n a{
    text-decoration: none;
    color: #F5F5F5;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}