.submenu-list {
  /*border:1px solid rgba(97, 125, 255, .2);
  border-radius: 10px;
  background: #a83939;
  padding:25px;*/

  background-image: url(../../../images/bg2.webp);
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(97, 125, 255, 0.2);
  padding: 10px;
}

.submenu-headings {
  font-size: 17px;
}

.submenu-list ul {
  margin: 0px;
  padding: 0px;
}

.submenu-list ul li {
  list-style: none;
  text-align: left;
  font-weight: bold;
  padding: 0px 20px;
}
.submenu-list ul li a {
  color: #000;
  text-decoration: none;
  display: block;
}
.submenu-list ul li a svg {
  float: right;
  margin-top: 8px;
}
.sub-menu {
  display: none;
}

.sub-menu.is-open {
  padding-top: 10px;
  display: block;
  color: white;
  /* font-weight: bold; */
  padding-left: -10px;
  padding-right: -10px;
}

@media only screen and (max-width: 600px) {
  .query {
    margin-top: 10px !important;
  }
}
