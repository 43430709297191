.text-part-container {
    padding-left: 5rem;
    display: flex;
    height: 90%;
    flex-direction: column;
    justify-content: flex-end;
    color: #F5F5F5;

}

.hero-title{
    font-size: 3.5rem;
    text-shadow: 3px 3px 1px #670707;
    z-index: 2;
    font-family: video-game;

}

.Description{
    font-size: 1.5rem;
    z-index: 2;
}

.red{
    color: #F5F5F5;
}
.title-with-logo{
    display: flex;
    align-items: center;
}

.title-with-logo img{
    width: 128px;
}