.statistics-container{
    background: #202020;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    margin: 5rem 0 -3rem 0;
}

.statistics-row1{
    width: 70%;
    display: flex;
    justify-content: center;
    text-align: center;
}

.statistics-number{
    font-size: 2rem;
}