.article-section {
    margin-top: 50px;
    margin-bottom: 50px;
}

.article-container {
    margin: auto;
    margin-top: 15px;
    width: 90%;
    max-width: 50rem;
    justify-content: center;
    border-radius: 15px;
    height: 100px;
}

.article-text {
    color: black;
    background: #E9E9E9;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    justify-content: left;
    height: 100%;
    padding-left: 30px;
}

.article-text p {
    margin: 0px;
    padding-top: 3px;
    opacity: 0.7;
}

.article-text h2 {
    margin: 0px;
    font-weight: bolder;
   font-size: calc(1.2rem + .6vw);
}

.gradient-image {
    padding: 0px;
    height: 100%;
    position: relative;
}

.gradient-image img {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.gradient {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to right, #E9E9E9, transparent);
    position: absolute;
    top: 0;
    bottom: 0;
}

/* Article page styling: */

.article {
    padding-top: 9rem;

}

.main-content {
    padding-bottom: 4rem;
    margin: auto;
    width: 90%;
    max-width: 45rem;

}

.event-image {
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    margin-bottom: 1rem;
    border-radius: 10px;
    width: 100%;
    height: auto;
}

.article h1 {
    color: black;
}

.article h6 {
    font-weight: 300;
}

.article caption {
    width: 100%; /*isn't doing anything rn*/
}

.article p {
    font-weight: 300;
}

.article figcaption {
    font-weight: 300;

}

.article hr {
    margin: 0px;
    margin-bottom: 0.75rem;
}

.back-button {
    flex-wrap: nowrap;
    display: flex;
    margin-bottom: 2.5em;
}

.back-button p {
    color: black;
    padding-left: 0.5em;
    margin: 0px;
}

.back-button :hover {
    color:#BB3b3b;
}