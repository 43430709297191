body{
  padding: 0;
  margin: 0;
  font-family: Helvetica;
}
.Main-hero-part{
  height: 100vh;
  display: flex;
  background-size: cover;
  background-position: center;
  perspective: 500px;
}

.bg{
  width: 100vw;
  height: 100vh;
}

.bg img{
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.main-hero-img-part{
  width: 45%;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  background: rgba(119, 24, 24, 0.16);
}

.main-hero-text-part{
  width: 55%;
}

.about-us{
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #202020;
  color: white;
}



