@font-face {

}

#sec-sabatier-title{
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    background: url("../../../images/sabatier-img-dark.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    box-shadow: 0px 2px 16px #999;
}
.main-title{
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #f7f7f7;
}

#sec-details {
    padding: 5rem;
}

.main-title h1{
    font-size: 5rem;
    text-shadow: 5px 5px black;
}

.main-title h3{
    font-weight: lighter;
    letter-spacing: 2px;
    font-family: "Open Sans";
}
/* Title section ends */
/* The Idea Section */
#sec-the-idea{
    width: 100%;
}

.ideaInformations{
    display: flex;
    padding: 5rem 7.5rem;
    flex-direction: column;
    height: 100%;
    gap: 2rem;

    align-items: center;
    justify-content: center;

    background: url("../../../images/sabatierPage/sabatierbg.jpg");
    background-size: 102%;
    background-position: center;
    background-repeat: repeat-y;
}

.idea-title{
    color: #f4c15d;
    font-family: "Times New Roman";
    font-size: 3rem;
}

.idea-text{
    font-family: "Times New Roman";
    color: #f7f7f7;
    text-align: center;
    padding: 0 10%;
}

/* The Idea ends */

.title-seperator{
    background: url("../../../images/mars-bg.jpg");
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    background-position: center;
    background-size: cover;
}

.title-seperator h1{
    color: #f4c15d;
    font-family: "Times New Roman";
    font-size: 3rem;
}

/* Team */

#sec-teams{
    display: flex;
    background: #d9d9d9;
    flex-direction: column;
}

.title-teams{
    align-self: center;
    font-size: 3rem;
    color: #973a34;
    font-family: "Times New Roman";
}

.prevCapt-title{
    color: #b5a37f;
    font-family: "Times New Roman";
    font-size: 2.5rem;
}

.peoples{
    display: flex;
    width: 60%;
    justify-content: center;
    align-self: center;
    flex-wrap: wrap;
}

.prevCaptains{
    display: flex;
    width: 55%;
    align-self: center;
    flex-direction: column;
    padding-bottom: 1rem;
}

.prevCaptainBox{
    display: flex;
    flex-wrap: wrap;
}

.linkedin-link{
    text-decoration: none;
}

.person{
    width: 256px;
    height: 256px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 5px;
    text-shadow: -1px -1px 0 rgb(100, 100, 100), 1px -1px 0 rgb(100, 100, 100), -1px 1px 0 rgb(100, 100, 100), 1px 1px 0 rgb(100, 100, 100);
}

.prevCapt{
    width: 128px;
    height: 128px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 5px;
    color: white;
    text-shadow: -1px -1px 0 rgb(100, 100, 100), 1px -1px 0 rgb(100, 100, 100), -1px 1px 0 rgb(100, 100, 100), 1px 1px 0 rgb(100, 100, 100);
}

.person.dagen{
    background: url("../../../images/sabatierPage/team/DaganSchoen.png");
    background-size: cover;
    background-position: center;
}

.joya{
    background: url("../../../images/sabatierPage/team/Joya.png");
    background-size: cover;
    background-position: center;
}

.hang{
    background: url("../../../images/sabatierPage/team/Hang.png");
    background-size: cover;
    background-position: center;
}

.person.alyona{
    background: url("../../../images/sabatierPage/team/Alyona.png");
    background-size: cover;
    background-position: center;
}

.person.joanne{
    background: url("../../../images/sabatierPage/team/Joanne.jpeg");
    background-size: cover;
    background-position: center;
}

.person.kenza{
    background: url("../../../images/sabatierPage/team/kenza.jpg");
    background-size: cover;
    background-position: center;
}

.Garnet{
    background: url("../../../images/sabatierPage/team/Garnet.jpg");
    background-size: cover;
    background-position: center;
}

.Andrew{
    background: url("../../../images/sabatierPage/team/Andrew.png");
    background-size: cover;
    background-position: center;
}


.Yash{
    background: url("../../../images/sabatierPage/team/Yash.png");
    background-size: cover;
    background-position: center;
}


.unknown{
    background: url("../../../images/sabatierPage/rhiannon.png");
    background-size: cover;
    background-position: center;
}

.person h1{
    color: #f7f7f7;
}

.person h4{
    color: white;
    font-size: 0.8rem;
}
/* Team ends */

@media (max-width: 800px) {

    .ideaInformations {
        padding: 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .idea-title {
        padding-top: 2rem;
    }

    .idea-text{
        font-size: 1rem;
        text-align: left;
    }

    /*.logo {*/
    /*    width: 256px;*/
    /*}*/
    .icon{
        display: block;
    }
    .navbar .nav-items{
        position: fixed;
        top: 0;
        right: -100%;
        height: 100vh;
        width: 100%;
        max-width: 300px;
        display: block;
        padding: 40px 0;
        background: #2c3034;
        text-align: center;
        transition: all 1s ease;
    }

    .navbar .nav-items.active{
        right: 0;
    }

    .navbar .nav-items li{
        margin-top: 40px;
    }

    .navbar .nav-items li a{
        font-size: 16px;
    }

    .navbar{
        padding: 0;
    }

    .navbar .content .logo{
        margin: 0;
    }

}