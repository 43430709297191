


.img1 { 
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%); /* Adding -webkit- prefix */
  animation: animate 1.5s linear infinite;
}

@keyframes animate {
  45%, 50% {
    transform: translate(-50%, -50%) scale(1.4);
    -webkit-transform: translate(-50%, -50%) scale(1.4); /* Adding -webkit- prefix */
  }
}

.loaderMain {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: relative; /* Ensure the parent container has position set */
}

.loader1 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 10rem !important;
  min-width: 14rem;
}

.loader1:before,
.loader1:after {
  content: "";
  position: absolute;
  border-radius: 50%;

  animation: pulsOut 1.8s ease-in-out infinite;
  filter: drop-shadow(0 0 1rem rgba(247, 74, 74, 0.75));
}

.loader1:before {
  width: 100%;
  height: 100%;
  padding-bottom: 100%;
  box-shadow: inset 0 0 0 0.8rem rgb(219, 70, 70);
  animation-name: pulsIn;
}

.loader1:after {
  width: calc(100% - 2rem);
  padding-bottom: calc(100% - 2rem);
  box-shadow: 0 0 0 0 rgb(212, 69, 69);
}

@keyframes pulsIn {
  0% {
    box-shadow: inset 0 0 0 0.8rem rgb(226, 93, 93);
    opacity: 1;
  }
  50%,
  100% {
    box-shadow: inset 0 0 0 0 rgb(224, 46, 46);
    opacity: 0;
  }
}

@keyframes pulsOut {
  0%,
  50% {
    box-shadow: 0 0 0 0 rgb(209, 71, 71);
    opacity: 0;
  }
  100% {
    box-shadow: 0 0 0 0.8rem rgb(206, 60, 60);
    opacity: 1;
  }
}

.loaderMain {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

