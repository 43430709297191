ul {
  padding-left: 2rem;
}
i {
  text-decoration: none !important;
}
a {
  text-decoration: none !important;
}
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

img {
  vertical-align: middle;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

i[class^="flaticon-"]:before {
  font-family: flaticon !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.flaticon-twitter:before {
  content: "\f104";
}
.flaticon-linkedin:before {
  content: "\f105";
}
.flaticon-facebook:before {
  content: "\f107";
}
.flaticon-share-1:before {
  content: "\f13f";
}

img {
  max-width: 100%;
  height: auto;
}

.list-style {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-style li {
  list-style: none;
}
.social-profile.style1 li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.social-profile.style1 li a {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.social-profile li {
  display: inline-block;
  margin: 0 4px;
}
.social-profile li:first-child {
  margin-left: 0;
}
.social-profile li:last-child {
  margin-right: 0;
}
.social-profile li:last-child {
  margin-right: 0;
}
.social-profile li a {
  display: block;
  text-align: center;
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.social-profile li a i {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  line-height: 0.8;
}
.social-profile li a:hover {
  border-color: transparent;
  color: rgb(168, 57, 57);
}
.social-profile.style1 li a {
  width: 35px;
  height: 35px;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.social-profile.style1 li a {
  background: rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
}
.social-profile.style1 li a i {
  color: rgb(168, 57, 57);
}
.social-profile.style1 li a:hover {
  background-color: rgb(168, 57, 57);
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}
.social-profile.style1 li a:hover i {
  color: #fff;
}
.social-profile.style1 li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.social-profile.style1 li a {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.team-card {
  padding: 5px 25px 22px;
  position: relative;
  margin-bottom: 25px;
  z-index: 1;
  border-radius: 5px;
}
.team-card:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: calc(100% - 150px);
  background-color: #fff;
  -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.09);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.09);
  z-index: -1;
}
.team-card .team-member-img {
  width: 256px;
  height: 256px;
  border-radius: 50%;
  margin: 0 auto 22px;
  text-align: center;
}
.team-card .team-member-img img {
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  width: 234px;
  height: 238px;
}

.team-card .team-member-img .member-social {
  position: relative;
  top: -55px;
  left: 20px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  background-color: rgb(168, 57, 57);
}
.team-card .team-member-img .member-social i {
  color: #fff;
  font-size: 15px;
  line-height: 42px;
  margin: 0 auto;
}
.team-card .team-member-img .member-social .social-profile {
  position: absolute;
  bottom: 0;
  left: 0;
  min-height: 100%;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.team-card .team-member-img .member-social .social-profile li {
  display: block;
  margin: 10px 0;
}
.team-card .team-member-img .member-social .social-profile li:last-child {
  margin-bottom: 0;
}
.team-card .team-member-img .member-social .social-profile li a {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: rgb(168, 57, 57);
}
.team-card .team-member-img .member-social .social-profile li a i {
  color: #fff;
  font-size: 15px;
  margin: 0 auto;
}
.team-card .team-member-imfo {
  text-align: center;
}
.team-card .team-member-imfo h3 {
  font-size: 22px;
  margin: 0 0 5px;
}
.team-card .team-member-imfo span {
  color: #666666;
}
.team-card:hover .member-social {
  background-color: rgb(168, 57, 57);
}
.team-card:hover .member-social i {
  color: #fff;
}
.team-card:hover .member-social .social-profile {
  visibility: visible;
  opacity: 1;
  bottom: calc(100% + 15px);
}
.social-profile.style1 li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.social-profile.style1 li a {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media only screen and (max-width: 575px) {
  .team-card .team-member-img {
    width: 180px;
    height: 200px;
    margin: 0 auto 10px;
  }

  .team-card .team-member-img img {
    height: 190px;
  }
}
@media only screen and (max-width: 767px) {
  ul li {
    font-size: 14px;
  }
  .team-card .team-member-imfo h3 {
    font-size: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-card .team-member-imfo h3 {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .team-card .team-member-img {
    /* width: 212px; */
    height: 212px;
    padding-bottom: 250px;
  }
} 
@media only screen and (min-width: 1400px) {
  .team-card .team-member-img {
    width: 255px;
    height: 255px;
  }
}

@font-face {
  font-family: "flaticon";
  src: url("https://famous-cascaron-b61440.netlify.app/assets/fonts/flaticonbf9a.ttf?a8660cf949d06e2eac5f5f88a241ac68")
      format("truetype"),
    url("https://famous-cascaron-b61440.netlify.app/assets/fonts/flaticonbf9a.woff?a8660cf949d06e2eac5f5f88a241ac68")
      format("woff"),
    url("https://famous-cascaron-b61440.netlify.app/assets/fonts/flaticonbf9a.woff2?a8660cf949d06e2eac5f5f88a241ac68")
      format("woff2"),
    url("https://famous-cascaron-b61440.netlify.app/assets/fonts/flaticonbf9a.eot?a8660cf949d06e2eac5f5f88a241ac68#iefix")
      format("embedded-opentype"),
    url("https://famous-cascaron-b61440.netlify.app/assets/fonts/flaticonbf9a.svg?a8660cf949d06e2eac5f5f88a241ac68#flaticon")
      format("svg");
}
