* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container-fluid {
  padding-left: 0rem;
  padding-right: 0rem;
}

video {
  margin-top: 67px;
  object-fit: cover;
  height: auto;
  min-width: 100% !important;
}

.about-flexbox {
  display: flex;
  flex-direction: column;
}

.auction-card {
  margin-bottom: 25px;
}

.auction-card .auction-img {
  position: relative;
  overflow: hidden;
}

.auction-card .auction-img img {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.auction-card.style10 {
  border-radius: 0 0px 0 0px;
  padding: 4px 4px 4px;
  -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 8px rgb(255, 142, 142);
}

.auction-card.style10 .auction-img {
  border-radius: 0 0px 0 0px;
}

.auction-card.style10 .auction-img img {
  border-radius: 0 0px 0 0px;
}

.auction-card.style10:hover .auction-img img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

@media screen and (max-width: 768px) {
  video {
    margin-top: 0px;
  }
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container-fluid {
  padding-left: 0rem;
  padding-right: 0rem;
}

video {
  margin-top: 67px;
  object-fit: cover;
  height: auto;
  min-width: 100% !important;
}

.auction-card {
  margin-bottom: 25px;
}

.auction-card .auction-img {
  position: relative;
  overflow: hidden;
}

.auction-card .auction-img img {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.auction-card.style10 {
  border-radius: 0 0px 0 0px;
  padding: 4px 4px 4px;
  -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 8px rgb(255, 142, 142);
}

.auction-card.style10 .auction-img {
  border-radius: 0 0px 0 0px;
}

.auction-card.style10 .auction-img img {
  border-radius: 0 0px 0 0px;
}

.auction-card.style10:hover .auction-img img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

@media screen and (max-width: 768px) {
  video {
    margin-top: 0px;
  }
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container-fluid {
  padding-left: 0rem;
  padding-right: 0rem;
}

video {
  margin-top: 67px;
  object-fit: cover;
  height: auto;
  min-width: 100% !important;
}

.auction-card {
  margin-bottom: 25px;
}

.auction-card .auction-img {
  position: relative;
  overflow: hidden;
}

.auction-card .auction-img img {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.auction-card.style10 {
  border-radius: 0 0px 0 0px;
  padding: 4px 4px 4px;
  -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 8px rgb(255, 142, 142);
}

.auction-card.style10 .auction-img {
  border-radius: 0 0px 0 0px;
  max-height: 373px;
  min-height : 265px;
  overflow: hidden;
  object-fit: contain;
}

.auction-card.style10 .auction-img img {
  border-radius: 0 0px 0 0px;
}

.auction-card.style10:hover .auction-img img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

@media screen and (max-width: 768px) {
  video {
    margin-top: 0px;
  }
}
